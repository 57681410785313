import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import {Buffer} from "buffer";
import QRCode from "react-qr-code";

class login extends Component {
  state = {
    invoiceId: "",
    invoiceDate: "",
    customer: "",
    user: [],
    itemsData: []
  }
    componentWillMount() {
      axios.post("/is_authed").then((ok)=>{
        if(!ok.data) {
          this.props.history.push("/login");
        }
        if(!ok.data.access.includes("|44|")) {
            window.open("/panel", "_self");
            // this.props.history.push("/panel");
            return;
        }
        this.setState({user:ok.data});
      })
    }
    getMyDate = (date) => {
      return new Date(date).toLocaleString("en-GB", {timeZone: this.state.user.tz, hour12: "true"}).toLocaleUpperCase();
    }
    setStateSynchronous = (stateUpdate) => {
      return new Promise(resolve => {
          this.setState(stateUpdate, () => resolve());
      });
    }
    componentDidMount() {
      $("head").append('<link rel="stylesheet" title="myPrintPos80Style" href="/assets/style/print_pos_80.css" />');
      $("#root").removeAttr("class");
      $("#root").removeAttr("id");
      const base64InvoiceId = window.location.href.split("/")[window.location.href.split("/").length - 1];
      const invoiceId = Buffer.from(base64InvoiceId, "base64").toString("utf8");
      this.setState({invoiceId:invoiceId});
      axios.post("/get_item_code_data_print", {id:invoiceId}).then(async(ok)=>{
        if(ok.data.status === "error") {
          window.toastr[ok.data.status](ok.data.msg);
          return;
        }
        const data = ok.data.allItemsCodes;
        const invDate = ok.data.itemInvoiceDate;
        const itemsData = [];
        for(var i = 0; i < data.length; ++i) {
            if(data[i]) {
                var str1 = "Code1:";
                var str2 = "Code2:";
                var str3 = "Code3:";
                var str4 = "Code4:";
                if(data[i].code1 && data[i].code2 && !data[i].code3 && !data[i].code4) {
                    if(data[i].code1.length === 15 && data[i].code2.length === 13) {
                        str1 = "Recharge Code:";
                        str2 = "SN:"
                    }
                }
                itemsData.push([
                    <>
                      <div style={{justifyContent: "space-between", display: "grid"}}>
                          <label style={{fontWeight: "700"}}>{data[i].code_name}</label>
                          {data[i].code1 && <label style={{fontWeight: "100"}}><label style={{fontWeight: "700"}}>{str1}</label> {data[i].code1}</label>}
                          {data[i].code2 && <label style={{fontWeight: "100"}}><label style={{fontWeight: "700"}}>{str2}</label> {data[i].code2}</label>}
                          {data[i].code3 && <label style={{fontWeight: "100"}}><label style={{fontWeight: "700"}}>{str3}</label> {data[i].code3}</label>}
                          {data[i].code4 && <label style={{fontWeight: "100"}}><label style={{fontWeight: "700"}}>{str4}</label> {data[i].code4}</label>}
                          {data[i].code_name.includes("Alfa") && <QRCode size={256} style={{ height: "auto", maxWidth: "100%", width: "35%", justifySelf: "center", marginBottom: "6px"}} value={"tel:*14*"+data[i].code1+"#"} viewBox={`0 0 256 256`} />}
                          {data[i].code_name.includes("MTC") && <QRCode size={256} style={{ height: "auto", maxWidth: "100%", width: "35%", justifySelf: "center", marginBottom: "6px"}} value={"tel:*200*"+data[i].code1+"#"} viewBox={`0 0 256 256`} />}
                      </div>
                      <h1 style={{marginTop: "0px", borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    </>
                ])
            }
        }
        await this.setStateSynchronous({itemsData:itemsData, branch:data[0].branch, invoiceDate:this.getMyDate(invDate)});
        // window.print();
      })
    }

    render() {
        return(
            <>
              <div class="book">
                  <div class="page" style={{lineHeight: "1.1"}}>
                    <label style={{fontSize: "40px", justifyContent: "center", display: "grid"}}>MagicTech</label>
                    <label style={{fontSize: "15px", justifyContent: "center", display: "grid"}}>Branch ({this.state.branch})</label>
                    {/* <label style={{fontSize: "15px", justifyContent: "center", display: "grid"}}>Items Codes</label> */}
                    <label style={{fontSize: "15px", justifyContent: "center", display: "grid"}}>{this.state.invoiceDate}</label>
                    <br/>
                    <h1 style={{marginTop: "10px", borderTop: "1px solid", marginBottom: "10px"}}></h1>
                    {this.state.itemsData}
                  </div>
              </div>
            </>
        )
    }
}

export default login;
