import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import {Buffer} from "buffer";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: "",
        allowCost: "",
        itemsCats: [],
        branchesList: [],
        suppliersList: [],
        itemIdLogsHistory: "",
        setupTable: "",
        plainBranchesList: []
    }
    async setupDatatable() {
        const self = this;
        function getMyDate(date) {
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        window.$('#table1').DataTable({
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const selectedCat = $("#selectedCat").val();
                await axios.post('/compare_items_table', {cat:selectedCat, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    resData[vCount] = JSON.parse(resData[vCount]);
                    const id = resData[vCount].id;
                    const name = Buffer.from(resData[vCount].name, "hex").toString("utf8");
                    const category = resData[vCount].category;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"

                    // if(self.state.user.access.includes("|8|")) {
                    //     buttons += "<a class='myAStyle' id='delete_admin' itemId='"+id+"' itemName='"+name+"' style='color: red !important;'>Delete</a>";
                    // }
                    // if(self.state.user.access.includes("|9|")) {
                    //     buttons += "<a class='myAStyle' id='edit_admin' itemId='"+id+"' itemName='"+name+"' itemBarcode='"+barcode+"' \
                    //                 itemCost='"+cost+"' itemRetail='"+retail_price+"' itemWholesale='"+wholesale_price+"' itemQty='"+qty+"' \
                    //                 itemCat='"+category+"' itemWarnQty='"+warnQty+"' itemSupplier='"+supplier+"' itemBranch='"+branch+"'>Update Data</a>";
                    // }
                    // if(self.state.user.access.includes("|16|")) {
                    //     buttons += "<a class='myAStyle' id='move_stock' itemId='"+id+"' itemName='"+name+"' itemBarcode='"+barcode+"' \
                    //                 itemCost='"+cost+"' itemRetail='"+retail_price+"' itemWholesale='"+wholesale_price+"' itemQty='"+qty+"' \
                    //                 itemCat='"+category+"' itemWarnQty='"+warnQty+"' itemSupplier='"+supplier+"' itemBranch='"+branch+"'>Move Stock</a>";
                    // }
                    // if(self.state.user.access.includes("|9|")) {
                    //     buttons += "<a class='myAStyle' id='update_stock' itemId='"+id+"' itemName='"+name+"' itemBarcode='"+barcode+"' \
                    //                 itemCost='"+cost+"' itemRetail='"+retail_price+"' itemWholesale='"+wholesale_price+"' itemQty='"+qty+"' \
                    //                 itemCat='"+category+"' itemWarnQty='"+warnQty+"' itemSupplier='"+supplier+"' itemBranch='"+branch+"'>Update Stock</a>";
                    // }
                    // if(self.state.user.access.includes("|21|")) {
                    //     buttons += "<a class='myAStyle' id='view_item_history' itemId='"+id+"' itemName='"+name+"'>History</a>";
                    // }
                    buttons += "</div></div>";

                    out.push([]);
                    out[vCount].push(id, name);

                    for(var i2 = 0; i2 < self.state.plainBranchesList.length; ++i2) {
                        const brData = resData[vCount][self.state.plainBranchesList[i2]];
                        out[vCount].push(brData);
                    }
                    out[vCount].push(category, buttons);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#delete_admin");
        $(document).on("click", "#delete_admin", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            window.$("#deleteAdminModal").modal("show");
        });
        $(document).off("click", "#view_item_history");
        $(document).on("click", "#view_item_history", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId, itemIdLogsHistory:itemId});
            $("#boxForItemId").val(itemId);
            window.$("#itemLogsHistory").modal("show");
            self.setupItemsHistory();
        });
        $(document).off("click", "#edit_admin");
        $(document).on("click", "#edit_admin", async function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemBarcode = $(this).attr("itemBarcode");
            const itemCost = $(this).attr("itemCost");
            const itemRetail = $(this).attr("itemRetail");
            const itemWholesale = $(this).attr("itemWholesale");
            const itemQty = $(this).attr("itemQty");
            const itemCat = $(this).attr("itemCat");
            const itemWarnQty = $(this).attr("itemWarnQty");
            const itemSupplier = $(this).attr("itemSupplier");
            const itemBranch = $(this).attr("itemBranch");

            await self.setupItemModal("#updateItemModal");
            $("#inItemCat2").val(itemCat);
            $("#inItemName2").val(itemName);
            $("#inItemBarcode2").val(itemBarcode);
            $("#inItemCost2").val(itemCost);
            $("#inItemRetailPrice2").val(itemRetail);
            $("#inItemWholeSalePrice2").val(itemWholesale);
            $("#inItemQty2").val(itemQty);
            $("#inItemWarnQty2").val(itemWarnQty);
            if($("#inSuppliers2").val() !== "No Permissions" && $("#inSuppliers2").val() !== "No Suppliers yet") {
                $("#inSuppliers2").val(itemSupplier);
            }

            if($("#inBranches2").val() !== "No Permissions" && $("#inBranches2").val() !== "No Branches yet") {
                $("#inBranches2").val(itemBranch);
            }

            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            // window.$("#deleteAdminModal").modal("show");
        });

        $(document).off("click", "#move_stock");
        $(document).on("click", "#move_stock", async function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemBarcode = $(this).attr("itemBarcode");
            const itemCost = $(this).attr("itemCost");
            const itemRetail = $(this).attr("itemRetail");
            const itemWholesale = $(this).attr("itemWholesale");
            const itemQty = $(this).attr("itemQty");
            const itemCat = $(this).attr("itemCat");
            const itemWarnQty = $(this).attr("itemWarnQty");
            const itemSupplier = $(this).attr("itemSupplier");
            const itemBranch = $(this).attr("itemBranch");

            await self.setupItemModal("#moveStockModal");
            $("#curItemName").html(itemName);
            $("#curItemCost").html(itemCost);
            $("#curItemRetail").html(itemRetail);
            $("#curItemWholesale").html(itemWholesale);
            $("#curItemQty").html(itemQty);
            $("#inItemQtyToMove").val("");
            $("#curItemBranch").html(itemBranch);
            $("#inBranches3").val(itemBranch);

            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            // window.$("#deleteAdminModal").modal("show");
        });

        $(document).off("click", "#update_stock");
        $(document).on("click", "#update_stock", async function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemCost = $(this).attr("itemCost");
            const itemQty = $(this).attr("itemQty");
            const itemCat = $(this).attr("itemCat");
            const itemBranch = $(this).attr("itemBranch");

            $("#curItemName2").html(itemName);
            $("#curItemCat2").html(itemCat);
            $("#curItemCost2").html(itemCost);
            $("#curItemQty2").html(itemQty);
            $("#curItemBranch2").html(itemBranch);

            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            window.$("#updateStockModal").modal("show");
        });
    }


    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|59|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#compare-items_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            axios.post("/get_branches").then(async(ok)=>{
                if(ok.data.status == "error") {
                    this.setState({branchesList:[
                        <option value="">{ok.data.msg}</option>
                    ]});
                } else {
                    var thData = [];
                    var tdData = [];
                    var data = [];
                    thData.push(<th>ID</th>);
                    tdData.push(<td></td>);
                    thData.push(<th>Item Name</th>);
                    tdData.push(<td></td>);
                    for(var i = 0; i < ok.data.msg.length; ++i) {
                        this.state.plainBranchesList.push(ok.data.msg[i]);
                        data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                        thData.push(<th>{ok.data.msg[i]}</th>);
                        tdData.push(<td></td>);
                    }
                    thData.push(<th>Category</th>);
                    tdData.push(<td></td>);
                    thData.push(<th>Action</th>);
                    tdData.push(<td></td>);
                    await this.setStateSynchronous({branchesList:data, tableThData:thData, tableTdData:tdData, setupTable:true});
                    this.setupDatatable();
                }
            })
            axios.post("/get_cats").then((ok)=>{
                if(ok.data.status == "error") {
                    this.setState({itemsCats:[
                        <option value="">{ok.data.msg}</option>
                    ]});
                } else {
                    var data = [];
                    for(var i = 0; i < ok.data.msg.length; ++i) {
                        data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                    }
                    this.setState({itemsCats:data});
                }
            })
            axios.post("/get_suppliers").then((ok)=>{
                if(ok.data.status == "error") {
                    this.setState({suppliersList:[
                        <option value="">{ok.data.msg}</option>
                    ]});
                } else {
                    var data = [];
                    for(var i = 0; i < ok.data.msg.length; ++i) {
                        data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                    }
                    this.setState({suppliersList:data});
                }
            })
            // $("#clickAddItemId").on("keyup", (e) => {
            //     console.log(e.key);
            // })
        })
    }
    
    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    render() {
        return(
            <>

            <div class="modal fade" id="deleteAdminModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Item
                        </div>
                        <div class="modal-body">
                            Are you sure you want to remove item ({this.state.adminToDel}) completely ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteItem();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteAdminModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="updateStockModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Update ({this.state.adminToDel}) Stock Qty
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Item ID:</th>
                                        <td>{this.state.adminIdToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Item Name:</th>
                                        <td id="curItemName2"></td>
                                    </tr>
                                    <tr>
                                        <th>Item Category:</th>
                                        <td id="curItemCat2"></td>
                                    </tr>
                                    <tr>
                                        <th>Item Cost:</th>
                                        <td id="curItemCost2"></td>
                                    </tr>
                                    <tr>
                                        <th>Item Qty:</th>
                                        <td id="curItemQty2"></td>
                                    </tr>
                                    <tr>
                                        <th>Branch:</th>
                                        <td id="curItemBranch2"></td>
                                    </tr>
                                    <tr>
                                        <th>Qty to Change:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <input class="myInputStyle" id="inItemQtyToChange" type="number" placeholder=""/>
                                            <div class="flex items-center mr-auto" style={{alignItems: "center", width: "fit-content"}}>
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="assignCodesId2" onChange={() => {
                                                    if(document.getElementById("assignCodesId2").checked) {
                                                        $("#assignCodesAreaId2").show();
                                                    } else {
                                                        $("#assignCodesAreaId2").hide();
                                                    }
                                                }} />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="assignCodesId2">Assign Codes</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr id="assignCodesAreaId2" style={{display: "none"}}>
                                        <th>Codes</th>
                                        <td>
                                            <textarea id="setCodesArea2" style={{width: "100%", height: "250px", border: "groove"}}></textarea>
                                            <div class="flex items-center mr-auto" style={{alignItems: "center", width: "fit-content"}}>
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="printOnSaleId2" />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="printOnSaleId2">Print When Sale</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>New Cost:</th>
                                        <td><input class="myInputStyle" id="inItemCostToChange" type="number" placeholder="Leave Empty to keep current Cost"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.updateItemStock("-");
                            }}>Remove Qty</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateItemStock("+");
                            }}>Add Qty</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="moveStockModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Move ({this.state.adminToDel}) Stock from Branch to another
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Current Item Name:</th>
                                        <td id="curItemName"></td>
                                    </tr>
                                    <tr>
                                        <th>Current Item Cost:</th>
                                        <td id="curItemCost"></td>
                                    </tr>
                                    <tr>
                                        <th>Current Item Retail Price:</th>
                                        <td id="curItemRetail"></td>
                                    </tr>
                                    <tr>
                                        <th>Current Item Wholesale Price:</th>
                                        <td id="curItemWholesale"></td>
                                    </tr>
                                    <tr>
                                        <th>Current Item Qty:</th>
                                        <td id="curItemQty"></td>
                                    </tr>
                                    <tr>
                                        <th>Currenty Branch:</th>
                                        <td id="curItemBranch"></td>
                                    </tr>
                                    <tr>
                                        <th>Qty to Move:</th>
                                        <td><input class="myInputStyle" id="inItemQtyToMove" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Branch to Move Stock to:</th>
                                        <td>
                                            <select id="inBranches3" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='moveItemStockBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.moveItemStock();
                            }}>Move</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="updateItemModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" tabIndex="0" onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        this.updateItem();
                    }
                }}>
                    <div class="modal-content">
                        <div class="modal-header">
                            Update ({this.state.adminToDel}) Item
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Item Category:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <select id="inItemCat2" className="myInputStyle">
                                                {this.state.itemsCats}
                                            </select>
                                            &nbsp;
                                            <button class='myBtnStyle' id="makeNewCategory2" tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                window.$("#addCatModal").off("shown.bs.modal").off("hidden.bs.modal");
                                                window.$("#addCatModal").on("shown.bs.modal", function () {
                                                    $("#updateItemModal").css({zIndex: "1000"});
                                                }).on("hidden.bs.modal", function () {
                                                    $("#updateItemModal").css({zIndex: "1050"});
                                                });
                                                window.$("#addCatModal").modal("show");
                                            }}>Add Category</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Name:</th>
                                        <td><input class="myInputStyle" id="inItemName2" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Barcode:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <input class="myInputStyle" id="inItemBarcode2" placeholder=""/>
                                            &nbsp;
                                            <button class='myBtnStyle' id='updateBarcodeBtn' tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                axios.post("/get_new_barcode").then((ok)=>{
                                                    if(ok.data.status === "success") {
                                                        $("#inItemBarcode2").val(ok.data.msg);
                                                    } else {
                                                        window.toastr["error"](ok.data.msg);
                                                    }
                                                })
                                            }}>Update Barcode</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Cost:</th>
                                        <td><input class="myInputStyle" id="inItemCost2" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Retail Price:</th>
                                        <td><input class="myInputStyle" id="inItemRetailPrice2" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Wholesale Price:</th>
                                        <td><input class="myInputStyle" id="inItemWholeSalePrice2" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Qty:</th>
                                        <td><input class="myInputStyle" id="inItemQty2" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Warning Qty:</th>
                                        <td><input class="myInputStyle" id="inItemWarnQty2" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Supplier:</th>
                                        <td>
                                            <select id="inSuppliers2" className="myInputStyle">
                                                <option value="">{"<Without Supplier>"}</option>
                                                {this.state.suppliersList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Branch:</th>
                                        <td>
                                            <select id="inBranches2" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='updateItemBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateItem();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addCatModal" role="dialog" tabindex="-1" style={{zIndex: "1051"}}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Parent Category:</th>
                                        <td>
                                            <select id="inParentCat2" className="myInputStyle">
                                                <option value="">{"<Make as Main Category>"}</option>
                                                {this.state.itemsCats}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Category Name:</th>
                                        <td><input class="myInputStyle" id="inCatName"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addItemBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addCat();
                            }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addItemModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" tabIndex="0" onKeyDown={(e) => {
                    if($("#setCodesArea").is(":focus")) {
                        return;
                    }
                    if(e.key === "Enter") {
                        this.addItem();
                    }
                }}>
                    <div class="modal-content">
                        <div class="modal-header">
                            Add new Item
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Item Category:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <select id="inItemCat" className="myInputStyle">
                                                {this.state.itemsCats}
                                            </select>
                                            &nbsp;
                                            <button class='myBtnStyle' id="makeNewCategory" tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                window.$("#addCatModal").off("shown.bs.modal").off("hidden.bs.modal");
                                                window.$("#addCatModal").on("shown.bs.modal", function () {
                                                    $("#addItemModal").css({zIndex: "1000"});
                                                }).on("hidden.bs.modal", function () {
                                                    $("#addItemModal").css({zIndex: "1050"});
                                                });
                                                window.$("#addCatModal").modal("show");
                                            }}>Add Category</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Name:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <input class="myInputStyle" id="inItemName" placeholder=""/>
                                            <div class="flex items-center mr-auto" style={{alignItems: "center", width: "fit-content"}}>
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="assignCodesId" onChange={() => {
                                                    if(document.getElementById("assignCodesId").checked) {
                                                        $("#assignCodesAreaId").show();
                                                    } else {
                                                        $("#assignCodesAreaId").hide();
                                                    }
                                                }} />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="assignCodesId">Assign Codes</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr id="assignCodesAreaId" style={{display: "none"}}>
                                        <th>Codes</th>
                                        <td>
                                            <textarea id="setCodesArea" style={{width: "100%", height: "250px", border: "groove"}}></textarea>
                                            <div class="flex items-center mr-auto" style={{alignItems: "center", width: "fit-content"}}>
                                                <input type="checkbox" class="queryAccess myCheckboxNoMargin" id="printOnSaleId" />
                                                <label class="queryAccess2 myLabelForCheckbox" style={{marginBottom: "0px", width: "max-content", fontSize: "15px"}} for="printOnSaleId">Print When Sale</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Barcode:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <input class="myInputStyle" id="inItemBarcode" placeholder=""/>
                                            &nbsp;
                                            <button class='myBtnStyle' id='genBarcodeBtn' tabIndex={0} type='button' style={{float: "right", minWidth: "fit-content"}} onClick={() => {
                                                axios.post("/get_new_barcode").then((ok)=>{
                                                    if(ok.data.status === "success") {
                                                        $("#inItemBarcode").val(ok.data.msg);
                                                    } else {
                                                        window.toastr["error"](ok.data.msg);
                                                    }
                                                })
                                            }}>Generate Barcode</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Cost:</th>
                                        <td><input class="myInputStyle" id="inItemCost" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Retail Price:</th>
                                        <td><input class="myInputStyle" id="inItemRetailPrice" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Wholesale Price:</th>
                                        <td><input class="myInputStyle" id="inItemWholeSalePrice" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Qty:</th>
                                        <td><input class="myInputStyle" id="inItemQty" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Item Warning Qty:</th>
                                        <td><input class="myInputStyle" id="inItemWarnQty" type="number" placeholder=""/></td>
                                    </tr>
                                    <tr>
                                        <th>Supplier:</th>
                                        <td>
                                            <select id="inSuppliers" className="myInputStyle">
                                            <option value="">{"<Without Supplier>"}</option>
                                                {this.state.suppliersList}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Branch:</th>
                                        <td>
                                            <select id="inBranches" className="myInputStyle">
                                                {this.state.branchesList}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addItemBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addItem();
                            }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Compare Items Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>

                <table className="table-modal" style={{width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        {/* <tr>
                            <th>Branch:</th>
                            <td>
                                <select id="selectedBranch" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    {this.state.branchesList}
                                </select>
                            </td>
                        </tr> */}
                        <tr>
                            <th>Category:</th>
                            <td>
                                <select id="selectedCat" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    {this.state.itemsCats}
                                </select>
                            </td>
                        </tr>
                        {/* <tr>
                            <th>Supplier:</th>
                            <td>
                                <select id="selectedSupplier" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    window.$('#table1').DataTable().ajax.reload(null, false);
                                }}>
                                    <option value="">All</option>
                                    {this.state.suppliersList}
                                </select>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
                <br/>

                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%", fontSize: "smaller"}}>
                    <thead>
                        <tr>
                            {this.state.tableThData}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {this.state.tableTdData}
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default main;
