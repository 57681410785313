import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './main';
import Login from './login';
import StaffLogin from './staff_login';
import PrintA4 from './print_a4';
import PrintPos80 from './print_pos_80';
import PrintItemsCodes from './print_items_codes';
import { BrowserRouter, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Route path="/panel" component={Main} />
    <Route path="/print-a4" component={PrintA4} />
    <Route path="/print-pos-80" component={PrintPos80} />
    <Route path="/print-items-codes" component={PrintItemsCodes} />
    <Route exact path="/" component={Main} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/staff" component={StaffLogin} />
  </BrowserRouter>
);
